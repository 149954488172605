<template>
  <PatientPage :patient-no="patientNo" page-title="Dose diary">
    <div v-if="isSelectedPatientLoading" class="py-10 text-center">
      <v-progress-circular :size="50" color="grey" indeterminate />
    </div>

    <template v-if="showInsulinList" #sub-header>
      <DoseTypeInfo :patient="patient" :isLoading="isLoading" />
    </template>

    <v-card
      v-if="showInsulinList"
      class="rounded-lg"
      data-testid="insulin-list"
    >
      <InsulinList :patient-no="patientNo" />
    </v-card>
    <NotEnabled v-if="showNotEnabled" :patient-no="patientNo" />
  </PatientPage>
</template>

<script>
import Vue from 'vue'
import PatientPage from '@/components/patient/PatientPage'
import InsulinList from '@/components/insulin/InsulinList'
import trialSettingsGettersMixin from '@/components/mixins/store/trialSettingsGettersMixin'
import selectedPatientGettersMixin from '@/components/mixins/store/selectedPatientGettersMixin'
import service from '@/services/patient-service'
import loadingState from '@/constants/loadingState'
import DoseTypeInfo from '@/components/DoseTypeInfo.vue'

export default {
  name: 'DoseDiary',
  mixins: [trialSettingsGettersMixin, selectedPatientGettersMixin],
  components: {
    NotEnabled: () => import('@/components/layout/NotEnabled'),
    InsulinList,
    PatientPage,
    DoseTypeInfo,
  },
  props: {
    patientNo: { type: String, required: true },
  },
  data() {
    return {
      state: loadingState.INITIAL,
      patient: null,
    }
  },
  computed: {
    insulinsEnabled() {
      return this.trialFeatureFlags(this.patientNo).insulinsEnabled
    },
    isLoading() {
      return this.state === loadingState.LOADING
    },
    isSelectedPatientLoading() {
      return (
        this.selectedPatientConfigurationLoadingState ===
          loadingState.LOADING ||
        this.selectedPatientConfigurationLoadingState === loadingState.INITIAL
      )
    },
    showInsulinList() {
      return !this.isSelectedPatientLoading && this.insulinsEnabled
    },
    showNotEnabled() {
      return !this.isSelectedPatientLoading && !this.insulinsEnabled
    },
  },
  methods: {
    loadPatient() {
      this.state = loadingState.LOADING
      service
        .getPatient(this.patientNo)
        .then(response => {
          this.state = loadingState.LOAD_SUCCEEDED
          this.patient = response.data
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          Vue.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
  created() {
    this.loadPatient()
  },
}
</script>
